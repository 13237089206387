// Colors
$colors: (
    background:     #000, //#f8f8f8,
    primary:        #718794,
    //secondary:      #0f0,
    //tertiary:       #00f,
    text:           #333,
    link:           #718794,
    link-hover:     purple,
    border-light:   #ccc
);

// Font settings
$font-stack: 'Encode Sans Condensed', sans-serif;

// Bootstrap breakpoints
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

// Animation settings
$duration: .3s;