@import url('https://fonts.googleapis.com/css2?family=Encode+Sans+Condensed:wght@100;600;700&display=swap');
@import 'helpers/variables';
@import 'helpers/mixins';
@import 'helpers/functions';
@import 'reset';


html, body {
    font-family: $font-stack;
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    background-color: color(background);
    //background-image: url('../images/background-min.png');
    //background-repeat: no-repeat;
    //background-position: top left;
    //background-attachment: fixed;
    //background-size: auto 100%;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
}

img {
    height: auto;
    max-width: 100%;
    width: auto;
}

//header#top {
//    background-color: white;
//    position: fixed;
//    top: 0;
//    left: 0;
//    width: 100%;
//    z-index: 150; // higher z-index than nav.off-canvas-nav .menu
//}

main {
    //flex: 1 0 auto;
    min-height: 100vh; // This fixes the huge white space on IE11
    //padding-top: 37px; // Same height as header#top

    .background-video {
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;

        video {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    #home {
        position: relative;
        min-height: 100vh;
        width: 100%;

        .wave {
            position: fixed;

            width: 200vw;
            left: 0;

            svg {
                height: auto;
                width: 100%;
            }

            &--top {
                top: 0;
            }

            &--bottom {
                bottom: 0;
            }
        }

        .bracket {
            height: 600px;
            margin-top: -300px;
            position: fixed;
            top: 50%;
            width: 90px;

            &--left {
                left: 50px;
            }

            &--right {
                right: 50px;
            }
        }

        .initials {
            align-items: center;
            color: #FFF;
            display: flex;
            font-size: 10rem;
            font-weight: 700;
            height: 100vh;
            letter-spacing: -1.1rem;
            justify-content: center;
            width: 100vw;

            span {
                color: color(primary);
            }

            @include respond-to(from-md) {
                font-size: 15rem;
                letter-spacing: -1.6rem;
            }

            @include respond-to(from-lg) {
                font-size: 20rem;
                letter-spacing: -2.2rem;
            }

            @include respond-to(from-xl) {
                font-size: 25rem;
                letter-spacing: -2.8rem;
            }

        }

        .scroll-icon {
            text-align: center;
            width: 100%;
            @include transform(translateY(-200%));

            svg {
                display: inline-block;
                height: auto;
                width: 50px;

                path {
                    fill: #FFF;
                }
            }
        }

        .title-block {
            align-items: center;
            color: #FFF;
            display: flex;
            min-height: 100vh;
            justify-content: center;
            position: relative;
            width: 100vw;

            .inner {
                position: relative;

                .type {
                    font-size: 1.5rem;
                    font-weight: 600;
                    margin: 0;
                    padding: 0 5px;

                    p {
                        display: inline;
                    }

                    @include respond-to(from-md) {
                        font-size: 2.85rem;
                    }

                    span:first-child {
                        color: color(primary);
                    }
                }

                h1 {
                    font-size: 5.5rem;
                    line-height: 0.83;
                    text-transform: uppercase;
                    margin: 0;

                    @include respond-to(from-md) {
                        font-size: 8rem;
                        line-height: 1;
                    }

                    @include respond-to(from-lg) {
                        font-size: 10rem;
                    }

                    span {
                        color: color(primary);

                        @include respond-to(up-to-sm) {
                            font-size: 6.3rem;
                            display: block;
                        }
                    }
                }

                .employed-at {
                    font-size: 1.4rem;
                    font-weight: 100;
                    margin: 0;
                    padding: 5px;

                    @include respond-to(from-md) {
                        font-size: 2.85rem;
                        margin: -1rem 0 -0.5rem 0;
                    }

                    a {
                        color: color(link);
                        font-weight: 700;
                        position: relative;
                        text-decoration: none;
                        @include transition(color 0.3s ease);

                        &::before {
                            background: #FFF;
                            content: '';
                            height: 3px;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 0;

                            @include transition(width 0.3s ease);
                        }

                        @include respond-to(fine) {

                            &:hover {
                                color: #FFF;
                                &::before {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }

                .linked-in {
                    align-items: center;
                    border: 3px solid #FFF;
                    border-radius: 50%;
                    color: color(primary);
                    display: flex;
                    height: 75px;
                    font-size: 10rem;
                    justify-content: center;
                    position: absolute;
                    top: calc(100% + 25px);
                    right: 50%;
                    width: 75px;

                    @include transform(translateX(50%));

                    @include respond-to(from-md) {
                        height: 150px;
                        top: 10px;
                        right: calc(100% + 25px);
                        width: 150px;
                        @include transform(none);
                    }

                    svg {
                        height: auto;
                        width: 50%;

                        path {
                            fill: color(primary);
                        }
                    }

                    @include respond-to(fine) {

                        &:hover {
                            background: #FFF;
                        }
                    }
                }

            }
        }

        .container {
            //background-color: color(primary);
            position: relative;

            //&::before {
            //    background-color: color(primary);
            //    content: '';
            //    height: 100%;
            //    position: absolute;
            //    top: 0;
            //    left: 100%;
            //    width: 50vw;
            //    z-index: 0;
            //}
        }
    }

    .content-container {
        padding: 30px 0;
    }
}


//footer#bottom {
//    background-color: #f5f5f5;
//    flex-shrink: 0;
//    width: 100%;
//}